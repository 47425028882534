$(document).ready(function() {
  $(".share-social").jsSocials({
    showCount: "inside",
    showLabel: false,
    shareIn: "popup",
    shares: [
      {
        share: "facebook",
        logo: "fab fa-facebook-f"
      },
      {
        share: "twitter",
        logo: "fab fa-twitter"
      },
      {
        share: "pinterest",
        logo: "fab fa-pinterest"
      },
      {
        share: "linkedin",
        logo: "fab fa-linkedin-in"
      }
    ]
  });

  UIkit.util.on(".about-nav a", "scrolled", function() {
    $(this)
      .parent()
      .addClass("active");
    $(this)
      .parent()
      .siblings()
      .removeClass("active");
  });

  var elm = document.querySelector(".about-nav ul");
  var ms = new MenuSpy(elm, {
    threshold: 50
  });
});

const menuMobileMapping = new MappingListener({
  selector: ".menu-wrapper",
  mobileWrapper: ".mobile-menu",
  mobileMethod: "appendTo",
  desktopWrapper: ".language-wrapper",
  desktopMethod: "insertBefore",
  breakpoint: 1201
}).watch();

const ultiMapping = new MappingListener({
  selector: ".top-header-right",
  mobileWrapper: "header",
  mobileMethod: "insertAfter",
  desktopWrapper: ".top-header-left",
  desktopMethod: "insertAfter",
  breakpoint: 768
}).watch();
